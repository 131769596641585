import Vue from 'vue'
import Router from 'vue-router'

import ShjMain from './page/shj/Main';
import ShjTel from './page/shj/Tel';
import ShjTelUnbind from './page/shj/TelUnbind';
import ShjPassword from './page/shj/Password';
import ShjWeixin from './page/shj/Weixin';
import ShjKf from './page/shj/Kf';
import ShjRecharge from './page/shj/Recharge';
import ShjChat from './page/shj/Chat';
import ShjLoginQlb from './page/shj/LoginQlb';
import ShjRedPack from './page/shj/RedPack';
import ShjCashOut from './page/shj/CashOut';
import ShjCashOutLog from './page/shj/CashOutLog';
import ShjQiliApp from './page/shj/QiliApp';
import ShjShare from './page/shj/Share';
import X5Page from './page/shj/X5Page.vue';

import SdkMain from './page/sdk/Main';
import SdkTel from './page/sdk/Tel';
import SdkTelUnbind from './page/sdk/TelUnbind';
import SdkPassword from './page/sdk/Password';
import SdkWeixin from './page/sdk/Weixin';
import SdkKf from './page/sdk/Kf';
import SdkRecharge from './page/sdk/Recharge';
import SdkQiliApp from './page/sdk/QiliApp';
import Gift from "@/page/sdk/Gift.vue";
import ExpenseCalendar from "@/page/sdk/ExpenseCalendar.vue";
import GiftRecord from "@/page/sdk/GiftRecord.vue";
import ContentView from "@/page/sdk/ContentView.vue";
import ContentList from "@/page/sdk/ContentList.vue";
import Jiugongge from "@/page/shj/jiugongge.vue";
import Gamespeed from "@/page/sdk/gamespeed.vue";
import DoupoScz from "@/page/sdk/DoupoScz.vue";
import DoupoPool from "@/page/sdk/DoupoPool.vue";
import DoupoPoolRecord from "@/page/sdk/DoupoPoolRecord.vue";


Vue.use(Router)

export default new Router({
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/main'
        },
        {
            path: '/shj/main',
            name: 'ShjMain',
            component: ShjMain
        },
        {
            path: '/shj/tel',
            name: 'ShjTel',
            component: ShjTel
        },
        {
            path: '/shj/telUnbind',
            name: 'ShjTelUnbind',
            component: ShjTelUnbind
        },
        {
            path: '/shj/password',
            name: 'ShjPassword',
            component: ShjPassword
        },
        {
            path: '/shj/weixin',
            name: 'ShjWeixin',
            component: ShjWeixin
        },
        {
            path: '/shj/kf',
            name: 'ShjKf',
            component: ShjKf
        },
        {
            path: '/shj/recharge',
            name: 'ShjRecharge',
            component: ShjRecharge
        },
        {
            path: '/shj/chat',
            name: 'ShjChat',
            component: ShjChat
        },
        {
            path: '/shj/loginQlb',
            name: 'ShjLoginQlb',
            component: ShjLoginQlb
        },
        {
            path: '/shj/redPack',
            name: 'ShjRedPack',
            component: ShjRedPack
        },
        {
            path: '/shj/cashOut',
            name: 'ShjCashOut',
            component: ShjCashOut
        },
        {
            path: '/shj/cashOutLog',
            name: 'ShjCashOutLog',
            component: ShjCashOutLog
        },
        {
            path: '/shj/qiliApp',
            name: 'ShjQiliApp',
            component: ShjQiliApp
        },
        {
            path: '/shj/share',
            name: 'ShjShare',
            component: ShjShare
        },
        {
            path: '/shj/jgg',
            name: 'ShjShare',
            component: Jiugongge
        },
        {
            path: '/shj/share',
            name: 'ShjShare',
            component: ShjShare
        },
        {
            path: '/X5Page',
            name: 'X5Page',
            component: X5Page
        },
        {
            path: '/sdk/main',
            name: 'SdkMain',
            component: SdkMain
        },
        {
            path: '/sdk/tel',
            name: 'SdkTel',
            component: SdkTel
        },
        {
            path: '/sdk/telUnbind',
            name: 'SdkTelUnbind',
            component: SdkTelUnbind
        },
        {
            path: '/sdk/password',
            name: 'SdkPassword',
            component: SdkPassword
        },
        {
            path: '/sdk/weixin',
            name: 'SdkWeixin',
            component: SdkWeixin
        },
        {
            path: '/sdk/kf',
            name: 'SdkKf',
            component: SdkKf
        },
        {
            path: '/sdk/recharge',
            name: 'SdkRecharge',
            component: SdkRecharge
        },
        {
            path: '/sdk/qiliApp',
            name: 'SdkQiliApp',
            component: SdkQiliApp
        },
        {
            path: '/sdk/gift',
            name: 'Gift',
            component: Gift
        },
        {
            path: '/sdk/giftRecord',
            name: 'GiftRecord',
            component: GiftRecord
        },
        {
            path: '/sdk/record',
            name: 'ExpenseCalendar',
            component: ExpenseCalendar
        },
        {
            path: '/sdk/content/view/:contentType/:contentId',
            name: 'ContentView',
            component: ContentView
        },
        {
            path: '/sdk/content/list/*',
            name: 'ContentList',
            component: ContentList
        },
        {
            path: '/sdk/gamespeed',
            name: 'GameSpeed',
            component: Gamespeed
        },
        {
            path: '/sdk/doupoScz',
            name: 'DoupoScz',
            component: DoupoScz
        },
        {
            path: '/sdk/doupoPool',
            name: 'DoupoPool',
            component: DoupoPool
        },
        {
            path: '/sdk/doupoPoolRecord',
            name: 'DoupoPoolRecord',
            component: DoupoPoolRecord
        },

    ]
})