<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">送充值</span>
        </div>

        <div class="main" style="overflow-y: auto;">
            <div style="font-size: 14px;font-weight: bold;color: #f56666;text-align: left;width: 100%;margin: 2px 0 5px;">
                登录游戏领福利啦
            </div>

            <div style="width: 100%;background-color: #ffffff;height: 30px;text-align: left;font-size: 12px;color: #555555;display: flex;align-items: center;justify-content: space-between;">
                <span v-if="todayTime > 0">
                    今日还需在线
                    <span style="color: #f56666;font-weight: bold;font-size: 14px;">
                        <van-count-down style="display: inline;color: #f37171;font-size: 12px;"
                                        ref="countDown"
                                        :time="todayTime"
                                        :auto-start="true"
                                        @finish="finish"/>
                    </span>
                </span>
                <span v-else>
                    今日任务已完成
                </span>

                <span>
                    已完成任务
                     <span style="color: #f56666;font-weight: bold;font-size: 14px;">
                        {{loginDays}}
                    </span>
                    天
                </span>
            </div>

            <div style="width: 100%;">
                <div v-for="item in itemList"  style="display: flex;margin-top: 10px;">
                    <div class="item-left" style="padding: 8px 0 8px 10px;box-sizing: border-box">
                        <span style="color: #f33838;font-size: 16px;font-weight: bold;">6元充值</span>
                        <span>{{item.desc}}</span>
                    </div>
                    <div class="item-right" :class="item.status === 2 && 'item-right-able'">
                        <span v-if="item.status === 1">未完成</span>
                        <span v-else-if="item.status === 2" @click="submit(item.type)">领取</span>
                        <span v-else-if="item.status === 3">已领取</span>
                    </div>
                </div>
            </div>
            <van-divider
                :style="{ color: '#3c7ada', borderColor: '#3c7ada', padding: '0' ,width: '100%'}">
                活动说明
            </van-divider>

            <div style="font-size: 12px;color: #888888;text-align: left;width: 100%;">1. 每日在线时长20分钟即完成当日在线任务，累计在线任务即可领取充值，最多可领30</div>
            <div style="font-size: 12px;color: #888888;text-align: left;width: 100%;margin-bottom: 15px;">2. 每个微信号/帐号参与一次</div>


        </div>
    </div>
</template>

<script>
    import {request} from '@/api/request'
    import Toast from "vant/lib/toast";
    import {Dialog} from "vant";
    import notice from '@/api/notice'
    import '../../css/commonpage.css'

    export default {
        name: "sdk-dp-scz",
        components: {
        },
        data() {
            return {
                showLoading: false,
                itemList: [
                    {name: '6七里币', type: 3, desc: '累计完成任务3天可领取', status: 1},
                    {name: '6七里币', type: 7, desc: '累计完成任务7天可领取', status: 1},
                    {name: '6七里币', type: 15, desc: '累计完成任务15天可领取', status: 1},
                    {name: '6七里币', type: 30, desc: '累计完成任务30天可领取', status: 1},
                    {name: '6七里币', type: 45, desc: '累计完成任务45天可领取', status: 1},
                ],
                todayTime: 0,
                loginDays: 0,
            }
        },
        mounted () {
            this.from = localStorage.getItem('from');
            this.initData();
        },
        methods: {
            initData() {
                this.showLoading = true;

                let options = {
                    url:"/sdkH5/welfareInfo",
                    data: {}
                };

                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.welfareData = res.data.data;
                        this.todayTime = res.data.data.leftTime * 1000;
                        this.loginDays = res.data.data.loginDays;
                        this.itemList[0].status = this.welfareData.status3;
                        this.itemList[1].status = this.welfareData.status7;
                        this.itemList[2].status = this.welfareData.status15;
                        this.itemList[3].status = this.welfareData.status30;
                        this.itemList[4].status = this.welfareData.status45;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            finish() {
                this.todayTime = 0;
                this.initData();
            },
            back() {
                this.$router.back()
            },
            submit(t) {
                this.showLoading = true;
                let options = {
                    url:"/sdkH5/getWelfare",
                    data: {
                        type : t
                    }
                };

                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        Toast('领取成功');
                        this.initData();
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },

        }
    }
</script>

<style scoped>
    .item-left {
        background-image: url(../../img/shj/award-bg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 70px;
        flex: 1;
        display: flex;
        flex-direction: column;
        font-size: 11px;
        color: #888888;
        align-items: flex-start;
        justify-content: space-evenly;
        padding-left: 10px;
    }
    .item-right {
        background-image: url(../../img/shj/unavailable-get.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 70px;
        width: 70px;
        color: #ffffff;
        font-size: 14px;
        line-height: 70px;
        font-weight: bold;
        user-select: none;
    }
    .item-right-able {
        background-image: url(../../img/shj/available-get.png);
        cursor: pointer;
    }

    .bind-text{
        font-size: 13px;
        color: #666666;
    }

    .van-cell {
        padding: 7px 10px;
    }
    /deep/ .van-field__control {
        color: #333333;
    }
</style>