<template>
    <div class="body-view">
        <div style="position: absolute;top: 10px;left: 0;right: 0;margin: 0 auto;" class="linear-text">
            幸运奖池
        </div>
        <div @click="back" style="display: flex;align-items: center;justify-content: center;width: 50px;height: 45px;position: absolute;top: 0">
            <img src="../../img/pool/back_pool.png" style="width: 30px;height: 20px;">
        </div>

        <div class="pool-main">
            <div class="pool-name">
                <span>{{poolData.poolName || '未开始' .replace('奖池', '')}}</span>
            </div>
            <div  style="margin-top: 20px;color: #FFF3E9;font-size: 10px;">
                <span>开奖进度：{{poolData.poolBalance || '--'}}</span>
            </div>

            <div style="width: 80%;font-size: 14px; color: #E54919;font-weight: bold;margin-top: 20px; background-color: rgba(251,243,212,0.76);height: 35px;line-height: 35px;text-align: left;padding-left: 20px;border-radius: 5px;">
                <span>我的中奖几率：{{poolData.myChance || '0%'}}</span>
            </div>
            <div class="top3-title">
                <img  src="../../img/pool/pool_top3_title.png" style="width: 135px;">
            </div>
            <div class="pool_top3">
                <div class="top1">
                    <div class="two-line" v-if="top1 !== '暂无'">
                        <span>{{top1.split('/')[0]}}</span>
                        <span>{{top1.split('/')[1]}}</span>
                    </div>
                    <div v-else>
                        <span>暂无</span>
                    </div>
                </div>
                <div class="top2">
                    <div class="two-line" v-if="top2 !== '暂无'">
                        <span>{{top2.split('/')[0]}}</span>
                        <span>{{top2.split('/')[1]}}</span>
                    </div>
                    <div v-else>
                        <span>暂无</span>
                    </div>
                </div>
                <div class="top3">
                    <div class="two-line" v-if="top3 !== '暂无'">
                        <span>{{top3.split('/')[0]}}</span>
                        <span>{{top3.split('/')[1]}}</span>
                    </div>
                    <div v-else>
                        <span>暂无</span>
                    </div>
                </div>
            </div>

        </div>

        <img @click="openRecord" src="../../img/pool/pool_record_btn.png" style="width: 75px;margin-top: 10px;position: absolute;top: 50px;right: 0;">

        <img @click="openMyRecord" src="../../img/pool/pool_my_btn.png" style="width: 75px;margin-top: 10px;position: absolute;top: 90px;right: 0;">

        <div @click="showRuleInfo" style="margin-top: 20px;position: absolute;top: 150px;right: 0;color: #f3f3f3;text-align: right;font-size: 11px;border-radius: 3px;cursor: pointer;
                background-color: rgba(253,227,89,0.34);padding: 3px 10px;">
            <span>规则介绍</span>
        </div>

    </div>
</template>

<script>
    import {request} from '@/api/request'
    import Toast from "vant/lib/toast";
    import {Dialog} from "vant";
    import notice from '@/api/notice'
    import '../../css/commonpage.css'

    export default {
        name: "sdk-dp-pool",
        components: {
        },
        data() {
            return {
                showLoading: false,
                poolData: {},
                top1: '暂无',
                top2: '暂无',
                top3: '暂无',
            }
        },
        mounted () {
            this.from = localStorage.getItem('from');
            this.initData();
        },
        methods: {
            initData() {
                this.showLoading = true;

                let options = {
                    url:"/sdkH5/poolInfo",
                    data: {}
                };

                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.poolData = res.data.data;
                        if (this.poolData.topThree) {
                            let length = this.poolData.topThree.length;
                            for (let i = 0; i < length; i++) {
                                if (i === 0) this.top1 = this.poolData.topThree[0]
                                if (i === 1) this.top2 = this.poolData.topThree[1]
                                if (i === 2) this.top3 = this.poolData.topThree[2]
                            }
                        }
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            back() {
                this.$router.back()
            },
            showRuleInfo() {
                Dialog.alert({
                    title: '规则介绍',
                    messageAlign: 'left',
                    message: '每期奖池可随机开出38.8-500元微信红包，玩家消费1元即有机会赢取奖池奖金，每次开奖后几率重新计算，达到开奖条件则立即开奖。奖金未成功发放者，请在中奖之日起5个工作日内联系客服领取，过期作废。',
                }).then(() => {
                });
            },
            openMyRecord() {
                this.$router.push("/sdk/doupoPoolRecord?page=my")
            },
            openRecord(){
                this.$router.push("/sdk/doupoPoolRecord")
            },

        }
    }
</script>

<style scoped>
    .linear-text {
        background: linear-gradient(0deg, #f5e01f 0%, #ffffff 70%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
        font-size: 18px;
    }
    .body-view {
        background-image: url(../../img/pool/pool_main.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 100vh;
        box-sizing: border-box;
    }
    .pool-main{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #ed6a0c;
        box-sizing: border-box;
        padding: 20px 20px 0;
    }
    .pool-name{
        background-image: url(../../img/pool/pool_name_view.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100px;
        height: 25px;
        color: #E54919;
        font-family: myFont,serif;
        margin-top: 30px;
    }
    .top3-title{
        font-size: 14px;
        font-weight: bold;
        color: #FFFFFF;
        margin-top: 20px;
    }
    .pool_top3{
        background-image: url(../../img/pool/pool_top3.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 330px;
        align-self: center;
        height:90px;
        color: #FFFFFF;
        font-weight: bold;
        margin-top: 30px;
        position: relative;
        font-size: 10px;
    }

    .two-line{
        display: flex;
        flex-direction: column;
    }
    .top1 {
        margin: -25px auto;

    }

    .top2 {
        position: absolute;
        left: 60px;
        top: 8px;
    }

    .top3 {
        position: absolute;
        top: 19px;
        right: 30px;
        text-align: center;
        width: 100px;
    }
</style>