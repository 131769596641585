import axios from './axios'

export async function request (options) {
  let _gameId = localStorage.getItem("gameId");
  if (options.data) {
    options.data.commonParam = {
      device: '',
      systemVersion: '',
      udid: '',
      channelId: '',
      gameId: _gameId,
      sdkVersion: '',
      time: new Date().getTime()
    };
  }

  return axios({
    url: options.url,
    method: options.method || 'post',
    data: options.data
  })
}

