<template>
    <div class="common-body-view">
        <div  class="common-title">
            <img @click="back" src='../../img/left.png' style="width: 20px; height: 20px;">
            <span class="common-title-text">{{ page === "my" ? "我的中奖纪录" : "奖池开奖纪录" }}</span>
        </div>

        <div  class="main">
            <div v-if="page !== 'my'" style="display: flex;width: 100%;height: 35px;justify-content: space-between;">
                <div class="search-btn" :class="searchActive === 0 && 'search-btn-active'" @click="onselectSearch(0)">全部</div>
                <div class="search-btn" :class="searchActive === 1 && 'search-btn-active'" @click="onselectSearch(1)">特等奖</div>
                <div class="search-btn" :class="searchActive === 2 && 'search-btn-active'" @click="onselectSearch(2)">一等奖</div>
                <div class="search-btn" :class="searchActive === 3 && 'search-btn-active'" @click="onselectSearch(3)">二等奖</div>
                <div class="search-btn" :class="searchActive === 4 && 'search-btn-active'" @click="onselectSearch(4)">三等奖</div>
            </div>

            <div v-if="dataList.length === 0" style="width: 100%;text-align: center;margin-top: 50px;font-size: 16px;color: #888888">
                <span>暂无记录</span>
            </div>
            <div v-else class="list-view" v-for="(item,i) in dataList" >
                <div style="font-size: 14px;display: flex;flex-direction: column;align-items: flex-start;">
                    <div class="pool-name">
                        <span  style="font-weight: bold;color: #444444;">{{item.poolName}}</span>
                        <span style="font-size: 11px;color: #888888;margin-left: 10px;"><van-icon name="clock-o" /> {{item.time}}</span>
                    </div>

                    <span style="font-size: 13px;margin-top: 2px;">
                        <span style="font-weight: bold;">{{item.winnerRoleInfo}}</span>
                        <span style="font-weight: bold;margin-left: 20px;"><van-icon name="balance-o" /> {{item.poolAmount}}</span>
                    </span>

                    <span v-if="item.myChance" style="font-size: 13px;color: #888888;margin-top: 2px;">
                        我的中奖率: <span style="">{{item.myChance || '0%'}}</span>
                    </span>
                </div>
            </div>
        </div>

        <myloading v-show="showLoading"></myloading>
    </div>
</template>

<script>
    import Toast from "vant/lib/toast";
    import {request} from '@/api/request'
    import myloading from '@/components/myloading'
    import '../../css/commonpage.css'

    export default {
        name: "doupo-pool-record",
        components: {
            myloading
        },

        data() {
            return {
                showLoading: false,
                dataList: [],
                page: '',
                searchActive: 0,
                gameId: '',
            }
        },
        mounted () {
            this.gameId = localStorage.getItem("gameId");
            this.page = this.$route.query.page;
            this.initPage();
        },
        methods: {
            onselectSearch(n) {
                this.searchActive = n;
                this.initPage();
            },
            initPage() {
                if (this.page === 'my') {
                    this.queryMyData();
                }
                else {
                    this.queryRecordData();
                }
            },
            back() {
                this.$router.back()
            },
            queryRecordData() {
                this.showLoading = true;
                let options = {
                    url:"/sdkH5/poolWinnerLog",
                    data: {
                        pageNo: 1,
                        pageSize: 100,
                        params : {
                            type: this.searchActive,
                            commonParam: {
                                gameId: this.gameId,
                            }
                        }
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.dataList = res.data.data.list;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            },
            queryMyData() {
                this.showLoading = true;
                let options = {
                    url:"/sdkH5/myWinnerLog",
                    data: {
                        pageNo: 1,
                        pageSize: 100,
                        params : {
                            type: this.searchActive,
                            commonParam: {
                                gameId: this.gameId,
                            }
                        }
                    }
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        this.dataList = res.data.data.list;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .main{
        overflow-y: auto;
    }

    .list-view{
        width: 100%;
        color: #E54919;
        padding: 10px 5px;
        box-sizing: border-box;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #ededed;
    }

    .search-btn {
        width: 20%;
        background-color: #faf7f7;
        color: #666666;
        font-size: 12px;
        text-align: center;
        line-height: 35px;
        user-select: none;
        cursor: pointer;
    }

    .search-btn-active {
        color: #f54747;
        font-weight: bold;
        background-color: #ffeeee;
        border: 1px solid #fce1e1;
    }

    .amout-text{
        font-family: myFont,serif;
        background: linear-gradient(0deg, #d22f06 30%, #f56b6b 95%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
</style>